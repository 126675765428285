const crudModal = {
    limparForm: function (form) {
        form.find('.is-invalid').removeClass('is-invalid').end()
            .find('.invalid-feedback').remove().end()
            .find('input:not(:checkbox):not(:radio), select, textarea').val('').end()
            .find(':checkbox').attr('checked', false).end()
            .find(':radio:first').attr('checked', true).end()
            .find('select').selectpicker('refresh');
    },
    preencherForm: function (form, data) {
        $.each(data, function (k, v) {
            let elemento = form.find('#' + k);
            if (elemento.length) {
                if (elemento.is(':checkbox, :radio')) {
                    elemento.attr('checked', v === 'S');
                } else {
                    elemento.val(v);
                }
            }
        })
    }, init(modal, container = $('#divInfraAreaTelaD')) {
        let form = modal.find('form');
        let table = container.find('.dataTable');
        container
            .on("click", '.btnNovo, .btnAlterar, .btnConsultar', function () {
                crudModal.limparForm(form);
                crudModal.preencherForm(form, $.fn.dataTable.getDataByButton($(this)));
                modal.find('input, select, textarea, .btnSalvar').attr('disabled', $(this).hasClass('btnConsultar')).end()
                    .find('select').selectpicker('refresh').end()
                    .modal();
            })
            .on("click", '.btnDesativar', function () {
                submitAjax({
                    url: form.data('action-desativar'),
                    data: $.fn.dataTable.getDataByButton($(this)),
                    success: function (msg) {
                        eproc.toast.success(msg);
                        table.DataTable().ajax.reload();
                    }
                });
            })
            .on("click", '.btnSalvar', function () {
                submitAjax({
                    url: form.attr('action'),
                    data: form.serialize(),
                    container: form,
                    success: function (msg) {
                        eproc.toast.success(msg);
                        modal.modal('hide');
                        table.DataTable().ajax.reload();
                    }
                });
            });
    }
};

module.exports = crudModal;