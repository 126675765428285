import 'focus-within-polyfill'
import eproc from './eproc'

require('./datatables')
window.plupload = require('plupload')
window.crudModal = require('./crudModal.js');

(
    function($, eproc) {
        $(function() {
            eproc.init()
        })
    }
)(jQuery || $, eproc)